import React from 'react';
import {Modal} from '@redq/reuse-modal';
import Sticky from 'react-stickynode';

import {ResetCSS} from '../common/assets/css/style';
import Navbar from '../containers/AppModern/Navbar';
import DashboardFeatures from '../containers/AppModern/Dashboard';
import Newsletter from '../containers/AppModern/Newsletter';
import Footer from '../containers/AppModern/Footer';
import GlobalStyle, {AppWrapper, ContentWrapper} from '../containers/AppModern/appModern.style';
import '@redq/reuse-modal/es/index.css';

import SEO from '../components/seo';
import EveryPageWrapper from '../components/EveryPageWrapper';

export default function () {
	return (
		<EveryPageWrapper>
			{({domainTitle}) => (
				<>
					<SEO title={`${domainTitle} - Activate your life: CHALLENGE ACCEPTED`} />
					<Modal />
					<ResetCSS />
					<GlobalStyle />

					<AppWrapper>
						<Sticky top={0} innerZ={9999} activeClass="sticky-active">
							<Navbar />
						</Sticky>
						<ContentWrapper>
							<DashboardFeatures />
							<Newsletter />
						</ContentWrapper>
						<Footer />
					</AppWrapper>
				</>
			)}
		</EveryPageWrapper>
	);
}
